.markdown-content, .html-content {
  /* 基础字体样式 */
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* 定义标题、段落等元素的样式 */
.markdown-content h1, .html-content h1,
.markdown-content h2, .html-content h2,
.markdown-content p, .html-content p {
  /* 示例样式 */
  margin: 1em 0;
}

/* 其他需要的样式 */


.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.menu-bar {
  background-color: #f0f0f0;
  padding: 10px;
  text-align: left;
}

.content {
  display: flex;
  flex: 1;
}

.markdown-content {
  flex: 30%;
  overflow-y: auto;
  padding: 10px;
  border-right: 1px solid #ddd;
}

.html-content {
  flex: 70%;
  overflow-y: auto;
  padding: 10px;
}
